<!--
* @description:
* @fileName tagEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="500px" @close="close">
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="分类名称" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { addCategory, editCategory } from '@/api/content'

export default defineComponent({
  name: 'tagEdit',
  emits: ['fetch-data'],
  props: {
    enterpriseCode: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      formRef: null,
      treeRef: null,
      checkMenu: [],
      form: {},
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入标签名称' }],
        type: [
          { required: true, trigger: 'change', message: '请选择标签类型' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      list: [],
    })

    const showEdit = async (row) => {
      if (!row) {
        state.title = '添加'
      } else {
        state.title = '编辑'
        state.form = Object.assign({}, row)
      }
      state.dialogFormVisible = true
    }
    const close = () => {
      state['formRef'].resetFields()
      state.form = {
        btnRolesCheckedList: [],
      }
      state.dialogFormVisible = false
    }

    const save = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          // let isEnterpriseItem = localStorage.getItem('isEnterpriseItem')
          // if (isEnterpriseItem) {
          //   let item = JSON.parse(isEnterpriseItem)
          //   state.form.enterpriseCode = item.enterpriseCode
          // }
          let msg = ''
          
          if (state.title == '编辑') {
            let data = await editCategory(state.form)
            msg = data.msg
          } else {
            let data = await addCategory(state.form)
            msg = data.msg
          }
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          emit('fetch-data')
          close()
        }
      })
    }
    return {
      ...toRefs(state),
      showEdit,
      close,
      save,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}
</style>
